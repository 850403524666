import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";

type TAutoResizeTextProps = {
  text: string;
  defaultFontSize: number;
  defaultMinFontSize?: number;
  className?: string;
};
/**
 * 自动调整字体大小
 * @param props
 * defaultFontSize 默认字体大小
 * defaultMinFontSize 最小字体大小。如果不希望字体小于最小字体, 可以设置defaultMinFontSize
 * className 样式类
 * @constructor
 */
export const AutoResizeText = (props: TAutoResizeTextProps) => {
  const { text, className, defaultFontSize, defaultMinFontSize } = props;
  const minFontSize = defaultMinFontSize ?? 10;
  const [fontSize, setFontSize] = useState(props.defaultFontSize); // 初始字体大小
  const spanRef = useRef(null);

  useEffect(() => {
    // 计算文字的长度来设置字体大小
    const length = text.length;
    let newFontSize = defaultFontSize - (length / 50) * (defaultFontSize - minFontSize);
    newFontSize = Math.max(minFontSize, Math.min(newFontSize, defaultFontSize));
    setFontSize(newFontSize);
  }, [text]);
  return (
    <span
      ref={spanRef}
      className={clsx(className)}
      style={{ fontSize: `${Math.floor(fontSize)}px` }}>
      {text}
    </span>
  );
};
