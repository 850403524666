import {
  apiKeyBugsnag,
  IGNORE_BUGSNAG_ERROR,
  DOMAIN_GREEN,
  DOMAIN_GREEN_PARTNER,
  CUSTOMER_ID,
  USER,
  DOMAIN_GREEN_MANAGER
} from "../config/index";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { isClient } from "./common";

const bugsnagInit = () => {
  // 初始化错误监控服务
  Bugsnag.start({
    apiKey: apiKeyBugsnag,
    plugins: [new BugsnagPluginReact()],
    user: {
      // @ts-ignore
      id: isClient && sessionStorage.getItem(CUSTOMER_ID),
      name: "",
      // @ts-ignore
      email: isClient && sessionStorage.getItem(USER)
    },
    releaseStage: process.env.NODE_ENV,
    autoTrackSessions: false,
    enabledErrorTypes: { unhandledRejections: false },
    // 自定义错误报告 https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#groupinghash
    onError: (event) => {
      // 忽略的错误 IGNORE_BUGSNAG_ERROR
      const isIgnoreError = IGNORE_BUGSNAG_ERROR.some((item) =>
        String(event.errors?.[0]?.errorMessage)?.includes(item)
      );
      // 绿环境不上报错误
      // @ts-ignore
      const hostname: string = isClient && location.hostname;
      if (
        isIgnoreError ||
        hostname === DOMAIN_GREEN ||
        hostname === DOMAIN_GREEN_PARTNER ||
        hostname === DOMAIN_GREEN_MANAGER
      ) {
        return false;
      }
      const _message = event?.originalError?.message ?? "";
      event.context = _message?.toString();
      console.log("😭onError:", event.context);
      event.groupingHash = String(new Date().getTime()); // 覆盖默认的分组信息 https://docs.bugsnag.com/product/error-grouping/#custom-grouping-hash
    }
  });
};

/**
 * @param _errorData
 * @returns
 */
const _handleErrorData = (_errorData: any) => {
  // 隐藏密码
  if (_errorData?.config?.data?.includes("password")) {
    try {
      // JSON 对象类型
      const requestData = JSON.parse(_errorData?.config?.data);
      requestData.password = "******";
      _errorData.config.data = JSON.stringify(requestData);
    } catch (e) {
      // 字符串拼接参数类型
      _errorData.config.data = _errorData.config.data.replace(
        /password=.+?(?=(&|$))/,
        "password=******"
      );
    }
  }

  // 隐藏jwtToken
  if (_errorData?.config?.headers?.jwtToken) {
    _errorData.config.headers.jwtToken = "******";
  }

  // 不显示无用的这个数据，有很长的数字，影响查看其它数据
  if (_errorData?.request) {
    if (_errorData?.request?.socket) {
      _errorData.request.socket = "";
    }
    if (_errorData?.request?.agent) {
      _errorData.request.agent = "";
    }
  }
  return _errorData;
};

/**
 * @description: 格式化Bugsnag的错误信息展示
 * @params { error, type: 'XHR' | 'Ws...' | 'Action...' },尽量精简，方便在报警里展示更多可见信息，提升查看效率
 * @return Error
 */
const formatBugsnagMessage = (error: string, type = "XHR") => {
  try {
    // 接口错误，解析json数据，方便获取接口路径和状态
    let xhrErrorStr = "";
    let errorData = JSON.parse(error);

    let formatError = error;
    if (errorData?.config) {
      errorData = _handleErrorData(errorData);
      formatError = JSON.stringify(errorData, null, "\t");
    }

    if (type === "XHR") {
      // 获取接口路径
      const path = errorData?.data?.path || errorData?.config?.url || errorData?.url;
      xhrErrorStr = `--path: ${path}`;

      const msg =
        errorData?.message ||
        errorData?.data?.msg ||
        errorData?.data?.message ||
        errorData?.data?.status ||
        errorData?.statusText;
      const _responseCode = errorData?.data?.code ?? "";
      const _responseError = errorData?.data?.error ?? "";
      if (msg) xhrErrorStr += ` ${msg} ${_responseCode} ${_responseError}`;
    }

    // 错误展示格式 [type] (接口错误时加上-- [path] -- [status] -- [message])
    const message = `[${type}]${xhrErrorStr}--location:${location.pathname} --Error:${formatError}`;
    return message;
  } catch (e) {
    return `${type}--${error}`;
  }
};

export { bugsnagInit, formatBugsnagMessage };
