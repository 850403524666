import React from "react";
import { QueryClient } from "@tanstack/react-query";

export const ModalLoadingContext = React.createContext({ confirmLoading: false });

export const WalletContext = React.createContext<
    {
      fetchWalletInfo: any;
      showCircleModal: () => void;
      getCircleInfo: (params: any) => void;
    } | null>(null);

export const OperationContext = React.createContext<{
  token?: string;
  isOperation?: boolean;
  employeeIds?: string[];
} | null>(null);

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      gcTime: 0
      //staleTime: 1000 * 60 * 5, // 5 minutes
    }
  }
});

export const QueryClientContextClient = React.createContext({ queryClient });
