// 将components目录下的所有的组件都导出，请提供代码

import dynamic from "next/dynamic";

export * from "./asPagination/AsPagination";
export * from "./coinImage";
export * from "./commonChaceData";
export * from "./commonInput";
export * from "./commonLabelInput";
export * from "./compliance";
export * from "./countrySelect";
export * from "./customerHead";
export * from "./customMessage";
export * from "./datepicker";
export * from "./disclaimer";
export * from "./emptyContent/EmptyContent";
export * from "./footer";
export * from "./kycGuideTips";
export * from "./modalBase";
export * from "./modalKycGuide";
export * from "./numericInput/NumericInput";
export * from "./paginationTable";
export * from "./safetyTips";
export * from "./supportTips";
export * from "./switchLanguage";
export * from "./uploadFile";
export * from "./withCoinList/WithCoinList";
export * from "./withFiatAndSpotTrade/WithFiatAndSpotTrade";
export * from "./withKycInfo/WithKYCInfo";
export * from "./withLogging/WithLogging";
export * from "./withTrimInput/index";
export * from "./withVerifyEmailCode";
export * from "./withVerifyLoginCode";
export * from "./HtmlContentComponent";
export * from "./getHeader";
export * from "./commonVideo";
export * from "./commonCounter";
export * from "./autoResizeText";
