"use client";
import { useRef, useEffect } from "react";

export const useAsideHeight = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(([entry]) => {
      ref.current?.style?.setProperty("--aside-height", `${ref.current?.clientHeight}px`);
    });

    resizeObserver.observe(ref.current);
    return () => {
      if (!ref.current) return;
      resizeObserver.unobserve(ref.current);
    };
  }, []);

  return { ref };
};
