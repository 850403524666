import { BRAND } from "@aspen/model";
import { systemDetection } from "./systemDetection";

const system = systemDetection();

/**
 * ADGM中Venture改名为Deal，前端匹配处理
 * @param type Venture | VENTURE | venture 格式的字符串
 * @returns Deal | DEAL | deal 格式的字符串，保持原有大小写格式
 */
export const convertVentureToDeal = (type: string): string => {
  if (system === BRAND) {
    return type;
  }
  if (!/venture/i.test(type)) {
    return type;
  }
  return type.replace(/venture/gi, (match) => {
    if (match === match.toUpperCase()) return 'DEAL';
    if (match === match.toLowerCase()) return 'deal';
    return 'Deal';
  });
};
