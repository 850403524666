import { DEVICE_ID } from "../config/index";
import { getBrowserDeviceInfo, getQueryParams } from "./common";
import { reportEvent } from "./logUtils";

export const downloadGAReport = (moduleName: string) => {
  const deviceId = localStorage.getItem(DEVICE_ID) ?? "";
  const params = getQueryParams();
  const deviceInfo = getBrowserDeviceInfo();
  reportEvent({
    moduleName,
    // @ts-ignore
    detailParams: {
      ...params,
      ...deviceInfo,
      deviceId
    }
  });
};
