//下边这两个import不要进行合并优化,否则web build会失败;具体问题还不清楚
import { EN, ZH_CN } from "../config/constant/base";
import {  LANGUAGE,  } from "../config/constant/localStorageKeys";
import en from "./locales/en.json";
import zh from "./locales/zh.json";
import { isClient } from "./common";

const chekLanguage: () => string = () => {
  if (isClient) {
    localStorage.setItem(LANGUAGE, EN);
    if (location.pathname.includes(EN)) {
      localStorage.setItem(LANGUAGE, EN);
    }
    if (location.pathname.includes(ZH_CN)) {
      localStorage.setItem(LANGUAGE, ZH_CN);
    }
  }
  return !isClient ? EN : (localStorage.getItem(LANGUAGE) ?? "");
};

const i18nUtil = {
  locales: [EN, ZH_CN],
  locale: chekLanguage(),
  updateLocale: (language: string) => {
    i18nUtil.locale = language;
  },
  t: () => {
    let res;
    switch (i18nUtil.locale) {
      case ZH_CN:
        res = zh;
        break;
      case EN:
        res = en;
        break;
      default:
        res = zh;
    }
    return res;
  },
  formatMessage: (id: { id: string }, option?: object) => {
    const _idd: string = Object.values(id)[0] ?? "";
    let _str = i18nUtil.t()[_idd];
    let _resStr = _str;
    if (option) {
      Object.keys(option).map(() => {
        const keyReg = new RegExp(/\{.*?\}/g);
        const res = _str?.match(keyReg);
        if (res?.length > 0) {
          res.map((r: string) => {
            if (r && _str.includes(r)) {
              const _key = r.replace("{", "").replace("}", "");
              _resStr = _resStr?.replaceAll && _resStr.replaceAll(r, option[_key]);
            }
          });
        }
      });
    }
    return _resStr;
  }
};

export { i18nUtil };
