import type { IRes } from "./common";

/**
 * app传递过来的数据类型   因为从app传递的数据存在不确定性,所以数据不确定
 * 未使用
 */
export interface IMessageFromApp {
  type?:
    | "selectCurrency"
    | "setTotalAssets"
    | "setAssetsAllocation"
    | "setPerformance"
    | "showAssetsAmount"
    | "setRevenues"
    | "setDate"
    | "setPaddingHorizontal";
  data?: any; // 不修改
}

/**
 * 传递给app的数据类型
 * 未使用
 */
export interface IMessageToApp {
  type:
    | "showCurrencySelector"
    | "onLoad"
    | "showModal"
    | "changeAssetsDisplay"
    | "loadPnlReturn"
    | "showDatePicker"
    | "startUpload"
    | "uploadFailed"
    | "uploadSuccess"
    | "notLogin";
  data?: { pnlStartDate: string; pnlEndDate: string } | { url: string } | { message: string } | any; // 不修改
}

/**
 * app传递给h5数据base
 */
interface DataFromAppRes {
  code: string | number;
}

/**
 * 总资产信息
 */
export interface ITotalAssetsAppRes extends DataFromAppRes {
  data: IAssetWalletInfo;
}

/**
 * 资产分布信息
 */
export interface IAssetsAllocationAppRes extends DataFromAppRes {
  data: IAssetCurrencies[];
}

// 资产状况关键字段
export interface IAssetWalletInfo extends IRes {
  staticPeriod: string; // 现金账户金额
  spotAmount: number | string; // 现金账户金额
  strategyAmount: number | string; // 策略账户金额
  fundAmount: number | string; // fund账户金额
  yieldAmount: number | string; // 理财账户金额
  dualAmount: number | string; // 双币理财账户金额
  structuredAmount: number | string; // 结构化理财账户金额
  ventureAmount: number | string; // 风投总金额
  coldWalletAmount: string; // 冷钱包总金额
  hotWalletAmount: string; // 总金额 - 冷钱包总金额
  totalAmount: number | string; // 总金额
  todayRevenue: number | string; // 今日收益
  totalRevenue: number | string; // 累计收益(截止昨日)
  computeTime: number | string;
  customerId?: string; // 客户 id,
  strategyPendingAmount?: string; // 策略账户已经扣款金额,
  strategyPendingCurrency?: string; // Pending的币种信息,
  strategyProfitAmount?: string; // 策略账户收益,
  fundProfitAmount?: string; // 基金账户收益
}
// 资产币种分布
export interface IAssetCurrencies {
  currency: string;
  spotQuantity: number;
  yieldQuantity: number;
  dualQuantity: number; // 双币账户持仓数量
  accumulatorQuantity: number; // 累计期权持仓数量
  structuredAmount: number; // 结构化账户持仓数量
  strategyQuantity: number;
  ventureQuantity: number;
  coldWalletQuantity: number;
  totalUsdAmount: number;
  totalQuantity: number;
}
export interface IAssetSituational extends IRes {
  limit?: number;
  offset?: number;
  totalCount?: number;
  rows?: IAssetSituationalData[];
}
export interface IAssetSituationalData {
  assetDate: string;
  asset: number | string;
  assetDateFormat?: string;
}
// assets situational
export interface IReqAssetsSituational {
  type: number;
  offset: number;
  limit: number;
}

// 币种分析
export interface IReqAssetsCurrencies {
  topNumber: number;
}

// 收益曲线
export interface IReqAssetsRevenues {
  from: string;
  to: string;
}

/**
 * performance信息
 */
export interface IPerformanceAppRes extends DataFromAppRes {
  data: {
    totalAmount: string;
    rows: (IAssetSituationalData & {
      assetDateFormat?: string;
    })[];
  };
}

/**
 * 每日&总收益
 */
export interface IRevenueAppRes extends DataFromAppRes {
  data: {
    revenues: {
      [key: string]: string;
    };
    totalRevenues: {
      [key: string]: string;
    };
  };
}

/**
 * 每日&总收益
 */
export interface IPnl {
  revenues?: {
    assetDate: string;
    asset: number;
  }[];
  totalRevenues?: {
    assetDate: string;
    asset: number;
  }[];
}
export interface IOriginalItems {
  name:
    | "totalQuantity"
    | "spotQuantity"
    | "yieldQuantity"
    | "structuredAmount"
    | "strategyQuantity"
    | "fundQuantity"
    | "ventureQuantity"
    | "coldWalletQuantity";
  data: {
    totalUsdAmount: string | number;
    currency: string;
    totalQuantity: string | number;
  };
  value: string | number;
}

/* --------------------wallet相关-------------------- */
// 获取钱包信息
export interface IWalletInfo {
  showRevenue?: boolean;
}
// 提币/出金校验返回参数
export interface IVerifyParam {
  verifyCode: string;
  verifyType: string;
  businessType: number | string;
}

export interface IWalletVaultDepositAddress {
  currency: string;
  required: boolean;
}
//获取电子货币充值地址
export interface IWalletAssetsConfig {
  currency?: string;
  enableDeposit?: boolean;
  enableWithdraw?: boolean;
  isFiat?: boolean;
  walletType?: 0 | 1; // 0热钱包 1冷钱包
}

//获取电子货币充值地址
export interface IWalletDepositAddress {
  currency: string;
  required: boolean;
}

export interface IWalletAssetCurrencyConfig {
  currency: string;
}

export interface IReqVaultDepositWithdraw {
  address: string;
  amount: number | string;
  currency: string;
  customerId?: string;
  memo: string;
  outerBusinessId: string;
}

// 账户明细
export interface IWalletAccounts {
  nextToken?: string | null;
  limit: number;
}
export interface IReqStructuredAccountList {
  nextToken?: string | null;
  limit?: number;
  productId: string;
}
export interface IStructuredOrderDetail {
  settleMinuteBuffer?: number;
  amount: number;
  created: number;
  customerId: string;
  estimateApr: number;
  expiry: number;
  isReached: boolean | null;
  modified: number;
  orderId: string;
  paymentTime: null;
  productId: string;
  quote: string;
  remark: null;
  settleAmount: null;
  settleCurrency: null;
  state: string;
  statusDesc: string | null;
  strike: number;
  subscription: string;
  type: string;
  underlying: string;
}
export interface IWalletStructuredAccount {
  amount: number; // 持仓总数量,
  currency: string; // 币种
  customerId: string;
  productId: string;
  productName: string; // 产品名称
  type: string; // 产品类型: BuyLow or SellHigh
  usdAmount: number;
}
export interface IWalletSpotAccount {
  coldMoney: number; // 币种的冻结数量
  coldUsdMoney: number; // 计算出的冻结美元总额
  currency: string; // 币种
  hotMoney: number; // 币种的可用数量
  hotUsdMoney: number; // 计算出的可用美元总额
  totalMoney: number; // 币种的总数量
  totalUsdMoney: number; // 计算出的美元总额
}
export interface IWalletYieldAccount {
  apy: number; // 年化收益率0.08 = 8 %：后台手动录入
  baseCurrency: string; // 开启利息买币买的币种
  currency: string; // 币种
  interestTrading: boolean; // 是否开启利息买币：true开启了利息买币，false关闭了利息买币
  productId: string; // 产品id
  totalAmount: number; // 计算出的amount
  totalRevenue: number; // 截止目前获取的总利息
  totalAmountUsd: number; // 计算出的美元总额(原为totalUsdMoney)
  inRedeemAmount: number; // 赎回中的数量
  inRedeemAmountUsd: number; // 赎回中的金额
}
//法币充值
export interface IWalletDepositFiat {
  currency: string;
  otcOrderId: string; //充值参数id
  paymentWayId: string; //银行卡生成id
  referenceImage: string; //凭证图片地址
  referenceNo: string; //交易流水号
  remarkCode: string; //标记码
  requestAmount: number | string; //金额
  transferMethod: string; //转账方式 - 默认bank
}
//法币提取
export interface IWalletWithdrawFiat {
  verifyCode: number | string;
  verifyType: number | string;
  businessType: number | string;
  currency: string;
  otcOrderId: string; //充值参数id
  paymentWayId: string; //银行卡生成id
  asset: string;
  requestAmount: number | string; //金额
}
//电子货币提取
export interface IWalletWithdraw {
  currency: string;
  address: string; //地址
  outerBusinessId: string;
  memo: string; //备注 xrp
  amount: number | string; //金额
}
// adgm电子货币提取
export interface IAdgmWalletWithdraw {
  currency: string; // 币种
  customerId: string; // 客户ID
  amount: number | string; // 金额
  addressId: string; // 白名单地址
}
//电子货币充提历史
export interface IReqWalletHistory {
  type: number;
  offset: number;
  limit: number;
}
export interface IWalletHistory {
  accountId: string;
  address: string;
  amount: number;
  businessId: string;
  category: string; // ['NORMAL', 'INNER'],
  channelId: string;
  created: number;
  currency: string;
  customerId: string;
  customerName: string;
  fee: number;
  feeCurrency: string;
  id: string;
  memo: string;
  modified: number;
  operatorId: string;
  rejectReason: string;
  remark: string;
  status: string; // ['WITHDRAW_APPLY', 'WITHDRAW_APPROVED', 'WITHDRAW_REJECTED', 'WITHDRAW_COMPLETED', 'WITHDRAW_FAILED', 'WITHDRAW_NOT_IN_CHAIN', 'WITHDRAW_CANCELED', 'DEPOSIT_INITIAL', 'DEPOSIT_APPLY', 'DEPOSIT_FINISHED', 'DEPOSIT_FAILED'],
  txFee: number;
  txId: string;
  txStatus: number;
  type: string; //['DEPOSIT', 'WITHDRAW', 'DEPOSIT_HIDDEN'],
  usdtPrice: number;
  kycName: string;
  email: string;
}
//法币充提历史
export interface IReqWalletFiatHistory {
  type: string | number;
  nextToken?: null | string;
  limit?: number;
}
export interface IWalletFiatHistory {
  accountNo: string;
  amount: number;
  bankCharge: number;
  bankName: string;
  created: string;
  currency: string;
  customerId: string;
  fee: number;
  id: string;
  modified: string;
  paymentWayId: string;
  processStatus: string;
  referenceImage: string;
  referenceNo: string;
  rejectReason: string;
  remarkCode: string;
  requestAmount: number;
  side: string;
  status: string;
  transferMethod: string;
  userName: string;
}
export interface IWalletAddBankAccount {
  type: number;
  usage: number;
  bankName: string;
  swiftCode: string;
  country: string;
  city: string;
  street: string;
  accountNo: string;
  userName: string;
  countryCode: string;
  customerCity: string;
  customerPostal: string;
  customerStreet: string;
  proof: string;
}
export interface IBankAccount {
  accountNumber: string;
  bankName: string;
  city: string; // 银行开户城市,
  country: string; // 银行开户国家,
  countryCode: string; // 银行所属国家Code,
  created: string; // 创建时间,
  customerCity: string; // 用户所在城市,
  customerId: string;
  customerPostal: string; // 用户邮编,
  customerStreet: string; // 用户所在街道,
  district: string; // US/CA必须 ,
  iban: string; // 国际银行账号,
  id: string; //
  modified: string; // 修改时间,
  outerId: string; // 第三方关联id,
  proof: string; // 证明材料,
  remark: string; // 用户备注标记,
  routingNumber: string; // 号码 swift码 / ibc码,
  saasId: string;
  status: string; // 银行卡status = ['unaudited', 'approved', 'rejected', 'active', 'disable'],
  statusDesc: string[]; // 状态原因,
  street: string; // 街道详细地址,
  type: string; // 支付方式类型 = ['CARD', 'BANK_ACCOUNT'],
  usage: string; // 用途 = ['audit', 'circle'],
  userName: string; // 银行卡注册的用户真实姓名
}
//circle提取
export interface IWalletWithdrawCircle {
  asset: string;
  amount: number | string; //金额
}
// 资产配置
export interface ICurrencyConfig {
  assets: ICurrencyAsset[];
  available: number;
  currency: string;
  enableDeposit: boolean;
  enableWithdraw: boolean;
  fiat: boolean;
  icon: string;
  maxAvailable: number;
}

export interface ICurrencyAsset {
  addressType: string;
  asset: string;
  availableNetwork: number;
  chainName: string;
  chainType: string;
  currency: string;
  depositNetwork: number;
  enableDeposit: boolean;
  enableWithdraw: boolean;
  icon: string;
  id: number;
  memoLabel: string;
  minDepositAmount: number;
  precision: number;
  quotas: ICurrencyAssetQuotas[];
  showPrecision: number;
  withdrawFeeAmount: number;
  withdrawFeeCurrency: string;
  withdrawFeeRate: number;
  withdrawFeeType: string;
}

export interface ICurrencyAssetQuotas {
  calCurrency: string;
  category: string;
  created: string;
  freezeAmount: string;
  id: string;
  level: string;
  maxAmount: number;
  minAmount: number;
  modified: string;
  period: string;
  resource: string;
  saasId: string;
  type: string;
  usedAmount: string;
}

export interface IWalletStratgiesItem {
  id: string;
  customerId: string;
  strategyId: string;
  strategyName: string;
  strategyCode?: string;
  deductionTimeType: number;
  maxOneTimePurchaseQuota: string;
  startTime: number;
  endTime: number;
  currency: string;
  amount: number;
  amountUsd: number;
  pendingAmount: number; // pending中数据（未更新持仓
  pendingAmountUsd: number; // pending中数据（未更新持仓）
  ytd: number; // YTD
  ytdUsd: number; // YTD
  createTime: number;
  modifyTime?: number;
}
export interface IWalletFundsItem {
  id: string;
  customerId: string;
  fundId: string;
  fundName: string;
  fundCode?: string;
  deductionTimeType: number;
  maxOneTimePurchaseQuota: string;
  startTime: number;
  endTime: number;
  currency: string;
  amount: number;
  amountUsd: number;
  ytd: number; // YTD
  ytdUsd: number; // YTD
  createTime: number;
  modifyTime?: number;
}
export interface IWalletVentureItem {
  id: string;
  shares: number;
  type: number;
  customerId: string;
  customerEmail: string;
  ventureId: string;
  ventureName: string;
  currency: string;
  amount: number;
  modified: number;
  amountUsd: number;
  createTime: string;
  endTime: number;
  startTime: number;
  listIconAddressWeb: string;
  listIconAddressApp: string;
  unreadUpdatesCount: number;
  hasUpdates: boolean;
}
export interface IWalletFundItem {
  ytd: 0;
  modifyTime: string;
  id: string;
  shares: number;
  type: number;
  customerId: string;
  customerEmail: string;
  fundId: string;
  fundName: string;
  currency: string;
  amount: number;
  modified: number;
  amountUsd: number;
  createTime: string;
  endTime: number;
  startTime: number;
  listIconAddressWeb: string;
  listIconAddressApp: string;
  hasUpdates: boolean;
}
export interface IVentureUpdatesItem {
  id: string;
  saasId: string;
  language: string;
  publishTime: number;
  updatesStatus: string;
  updatesProductType: string;
  updatesProductIds: number[];
  title: string;
  details: string;
  image: string;
  created: number;
  modified: number;
  hasRead: boolean;
}
export interface IVentureUpdatesDetail {
  id: string;
  saasId: string;
  language: string;
  publishTime: number;
  updatesStatus: string;
  updatesProductType: string;
  updatesProductIds: number[];
  title: string;
  details?: string;
  image: string;
  created: number;
  modified: number;
  ventureInfoList: UpdatesVentureInfoVO[];
  fundInfoList: UpdatesVentureInfoVO[];
  hasRead: boolean;
  attach?: string;
}
export interface UpdatesVentureInfoVO {
  id: string;
  name: string;
  sort: number;
  currency: string;
  shares: number;
  type: number;
  startTime: string;
  endTime: string;
  listPictureOnSaleAddressWeb: string;
  listPictureOnSaleAddressApp: string;
  listPictureStopAddressWeb: string;
  listPictureStopAddressApp: string;
  listIconAddressWeb: string;
  listIconAddressApp: string;
  briefIntroduction: string;
  purchaseInformation: string;
  detailInformation: string;
  hasPosition: boolean;
  amount: string;
  attachmentName: string;
  attachmentUrl: string;
}
export interface ICircleBank {
  address: string;
  bankCode: string;
  city: string;
  country: string;
  name: string;
  networkFee?: number;
  swiftCode: string;
}
export interface IWalletFixedYield {
  id: string;
  productId: string;
  currency: string;
  customerId: string;
  shareAmount: number;
  shareQuantity: number;
  autoFinancing: boolean;
  fixedRevenue: number;
  financingRevenue: number;
  status: string;
  created: number;
  modified: number;
  productName: string;
  apy: number;
  lockStartTime: number;
  lockPeriod: number;
  saasId: string;
  fixedDepositOperateLogVOS: FixedDepositOperateLogVO[];
  fixedDepositOperateLogDTOS: FixedDepositOperateLogVO[];
  totalAmount: number;
  totalUsdMoney: number;
  apySteps: { min: number; max?: number; apy: number }[];
  financingApy: number;
  totalShareQuantity: number;
  productAutoFinancing: boolean;
  autoRenewal: boolean;
  productAutoRenewal: boolean;
}
export interface IWalletFixedYieldContractDetailItem {
  id: string;
  productId: string;
  currency: string;
  customerId: string;
  status: string;
  lockAmount: number;
  lockAmountUsd: number;
  lockedRevenue: number;
  previousLockedRevenue: number;
  previousLockedRevenueUsd: number;
  lockStartTime: number;
  lockEndTime: number;
  subscribeStartTime: number;
  apy: number;
  autoRenewal: boolean;
  autoRenewalPrincipal: boolean;
  created: number;
  modified: number;
  lockPeriod: number;
  productName: string;
  previousContracts: IWalletFixedYieldContractDetailItemHistory[];
}
export interface IWalletFixedYieldContractDetailItemHistory {
  id: string;
  productId: string;
  currency: string;
  customerId: string;
  status: string;
  lockAmount: number;
  lockedRevenue: number;
  lockStartTime: number;
  lockEndTime: number;
  apy: number;
  autoRenewal: boolean;
  autoRenewalPrincipal: boolean;
  created: number;
  modified: number;
}

export interface IWalletFixedYieldContractDetail {
  customerId: string;
  productName: string;
  totalLockAmount: number;
  totalLockAmountUsd: number;
  totalPreviousLockedRevenue: number;
  totalPreviousLockedRevenueUsd: number;
  currency: string;
  apy: number;
  lockPeriod: number;
  contractDetails: IWalletFixedYieldContractDetailItem[];
}
export interface FixedDepositOperateLogVO {
  id: string;
  productId: string;
  contractId: string;
  customerId: string;
  type: string;
  shareAmount: number;
  shareQuantity: number;
  autoFinancing: boolean;
  currency: string;
  status: string;
  created: number;
  modified: number;
  saasId: string;
  businessId: string;
  productName: string;
  totalAmount: number;
  totalUsdMoney: number;
  apy: number;
  lockPeriod: number;
  operatorType?: number | null;
}
export interface IWalletStakingYield {
  lockStartTime: number; // 计息开始时间,
  created: string; // 合约创建时间,
  currency: string; // 币种,
  customerId: string; // 客户ID,
  id: string; // 合约ID,
  toLockAmount: number; // 等待计息中金额,
  lockedRevenue: number; //累计收益
  lockedRevenueUsd: number;
  totalAmount: number; //总数量
  totalUsdAmount: number;
  inRedeemAmount: number; // 赎回中累计金额,
  inSubscribeAmount: number; // 认购中累计金额,
  interestType: number; // 利息质押, 0 = 关闭, 1 = 开启,
  enableRedeem: boolean; // 产品能否赎回, true - 可赎回, false - 不可赎回
  modified: string; // 合约更新时间,
  productId: string; // 产品ID,
  productInterestType: number; // 产品利息质押是否显示, 0 = 不展示, 1 = 展示,
  redeemConfirmPeriod: number; // 赎回等待天数,
  saasId: string; // 服务ID,
  lockAmount: number; // 持仓中累计金额,
  shareQuantity: number; // 份额 staking = 1,
  stakeShowApy: {
    min: number;
    max: number;
  }; // 展示年化利率,
  stakeContent: string; // staking 持仓列表 APY content
  stakeTitle: string; // staking 持仓列表 APY title
  status: string; // 合约状态 = ['SIGNED', 'IN_BEAR_CONFIRM', 'LOCKED', 'IN_REDEEM_CONFIRM', 'REDEEM']
}

export interface IChartDataValidate<T> {
  validate: boolean;
  data: T[];
}

export type IVaultType = "storage" | "withdraw";
// 查询用户是否开启提币白名单等信息
export interface IWalletWithdrawWhiteInfo {
  availableTime: number;
  enable: boolean;
  withdrawEnable: boolean;
}
// 请求数字货币提币地址列表
export interface IReqWalletWithdrawAddress {
  asset?: string;
  addressType?: string;
  nextToken?: string | null;
  limit?: number;
}
// 请求校验提币地址格式
export interface IReqWalletCheckWithdrawAddress {
  asset: string;
  address: string;
  memo?: string;
}
// 请求开启关闭提币白名单开关
export interface IReqWalletWithdrawWhiteListOnAndOff {
  enable: boolean;
}
// 删除提币地址
export interface IReqWalletWithdrawAddressDelete {
  addressId: string;
}
// 更新地址状态 开启、移除白名单
export interface IReqWalletWithdrawWhiteListUpdate {
  id: string;
}
// 数字货币提币地址列表
export interface IWalletWithdrawAddress {
  address: string;
  currency: string;
  asset: string;
  chainName: string;
  createTime: number;
  availableTime: number;
  customerId: string;
  type: string;
  id: string;
  remark: string;
  source: string;
  approveStatus: string | null;
  withdrawEnable: boolean;
  applyRole: number;
}
// 申请提币白名单历史
export interface IReqWithdrawWhiteListHistory {
  nextToken?: string | null;
  limit?: number;
}
// 新增提币地址
export interface IReqAddWithdrawAddress {
  asset: string;
  address: string;
  memo?: string;
  source: string;
  remark: string;
  addressType: string;
}

export type IAddAddressOriginType = "Exchange" | "Wallet";

export interface IOtcHistoryListRes extends IRes {
  data: { rows: IOtcHistoryItem[]; nextToken: string };
}

export interface IOtcHistoryItem {
  id: string;
  customerId: string;
  currency: string;
  time: number;
  amount: string;
}

/* --------------------end wallet相关-------------------- */
export interface IReportListItem {
  pdfUrl: string;
  reportDate: string;
}

export enum EUpdatesDetailType {
  venture = "VENTURE",
  fund = "FUND"
}
export interface IReqVenturesUpdates extends IWalletAccounts {
  productId: string;
  updatesProductType: string;
}
export interface IReqVenturesUpdatesDetail {
  productId: string;
  updatesId: string;
  productType: string;
}

export interface IVaspType {
  name: string;
  did: string;
}
export enum ICustomerType {
  INDIVIDUAL = "Individual",
  INSTITUTION = "Institution"
}
export interface IReqAddWhitelistAddress {
  asset: string;
  address: string;
  memo?: string;
  source?: string;
  customerType: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  city?: string;
  country?: string;
  location?: string;
  vaspDid?: string;
  vaspName?: string;
}

export interface IReqDeleteWhitelistAddress {
  addressId: string;
}
export enum WhitelistStatus {
  CREATED = "CREATED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  DELETED = "DELETED"
}
export interface IReqWhitelistAddress {
  asset?: string;
  statuses?: WhitelistStatus;
}

export interface IWalletWhitelistAddress {
  id: string;
  asset: string;
  address: string;
  memo?: string;
  currency: string;
  chainName: string;
  customerType: string;
  companyName?: string;
  firstName?: string;
  lastName?: string;
  city?: string;
  country?: string;
  location?: string;
  status: WhitelistStatus;
  created: number;
  modified: number;
  customerName?: string;
  canDeposit: boolean;
  canWithdraw: boolean;
  vaspDid?: string;
  vaspName?: string;
  availableTime?: string;
}
