// 组合路由
import {
  AGREEMENT_PATH,
  ASSETS_PATHS,
  DOWNLOAD_PATH,
  EVENTS_PATHS,
  HISTORY_PATHS,
  HOME_PATH,
  INTELLIGENCE_PATHS,
  LOGIN_PATHS,
  PARTNER_PATH,
  POLICY_PATH,
  PROFILE_PATHS,
  STRATEGIES_PATHS,
  STRUCTURED_PATH,
  TRADE_PATH,
  TRADE_PATH_DEFAULT_PATH,
  ADGM_TRADE_PATH_DEFAULT_PATH,
  VENTURES_PATHS,
  WALLET_PATHS,
  YIELD_PATHS,
  FUNDS_PATHS,
  TERMINATION_PATH
} from "./routingConstantConfig";

export const MENU_PATHS = [
  {
    id: "menu.home",
    to: HOME_PATH,
    kycLevel: 0
  },
  {
    id: "title.market",
    to: TRADE_PATH,
    // 动态路由的key高亮匹配和跳转， 与普通路由稍有不同
    dynamic: TRADE_PATH_DEFAULT_PATH,
    kycLevel: 0
  },
  {
    id: "compliance.yield.title",
    to: YIELD_PATHS.YIELD,
    kycLevel: 1
  },
  {
    id: "menu.structured",
    to: STRUCTURED_PATH.STRUCTURED,
    kycLevel: 2
  },
  {
    id: "menu.strategies",
    to: STRATEGIES_PATHS.STRATEGIES,
    kycLevel: 2
  },
  {
    id: "menu.ventures",
    to: VENTURES_PATHS.VENTURES,
    kycLevel: 2
  },
  {
    id: "menu.intelligence",
    to: INTELLIGENCE_PATHS.INTELLIGENCE,
    // 动态路由的key高亮匹配和跳转， 与普通路由稍有不同
    dynamic: INTELLIGENCE_PATHS.INTELLIGENCE_INSIGHTS,
    children: [
      {
        id: "menu.intelligence.insights",
        to: INTELLIGENCE_PATHS.INTELLIGENCE_INSIGHTS
      },
      {
        id: "menu.intelligence.news",
        to: INTELLIGENCE_PATHS.INTELLIGENCE_NEWS
      },
      {
        id: "menu.intelligence.research",
        to: INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH
      },
      {
        id: "menu.intelligence.expert.views",
        to: INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT
      }
    ],
    kycLevel: 0
  },
  {
    id: "menu.history",
    to: HISTORY_PATHS.HISTORY,
    kycLevel: 1
  },
  {
    id: "menu.wallet",
    to: WALLET_PATHS.WALLET,
    kycLevel: 1
  }
];
export const ADGM_MENU_PATHS = [
  {
    id: "menu.home",
    to: HOME_PATH,
    kycLevel: 0
  },
  {
    id: "title.market",
    to: TRADE_PATH,
    // 动态路由的key高亮匹配和跳转， 与普通路由稍有不同
    dynamic: ADGM_TRADE_PATH_DEFAULT_PATH,
    kycLevel: 0
  },
  {
    id: "savings.autoInvestment",
    to: YIELD_PATHS.YIELD_MY_INVESTMENT_PLAN,
    kycLevel: 1
  },
  {
    id: "menu.structured",
    to: STRUCTURED_PATH.STRUCTURED,
    kycLevel: 2
  },
  {
    id: "menu.fund",
    to: FUNDS_PATHS.FUNDS,
    kycLevel: 2
  },
  {
    id: "menu.ventures",
    to: VENTURES_PATHS.VENTURES,
    kycLevel: 2
  },
  {
    id: "menu.intelligence",
    to: INTELLIGENCE_PATHS.INTELLIGENCE,
    // 动态路由的key高亮匹配和跳转， 与普通路由稍有不同
    dynamic: INTELLIGENCE_PATHS.INTELLIGENCE_INSIGHTS,
    children: [
      {
        id: "menu.intelligence.insights",
        to: INTELLIGENCE_PATHS.INTELLIGENCE_INSIGHTS
      },
      {
        id: "menu.intelligence.news",
        to: INTELLIGENCE_PATHS.INTELLIGENCE_NEWS
      },
      {
        id: "menu.intelligence.research",
        to: INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH
      },
      {
        id: "menu.intelligence.expert.views",
        to: INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT
      }
    ],
    kycLevel: 0
  },
  {
    id: "menu.history",
    to: HISTORY_PATHS.HISTORY,
    kycLevel: 1
  },
  {
    id: "menu.wallet",
    to: WALLET_PATHS.WALLET,
    kycLevel: 1
  }
];

export const PARTNER_MENU_PATHS = [
  {
    id: "client.dashboard",
    to: PARTNER_PATH.DASHBOARD
  },
  {
    id: "client.customer",
    to: PARTNER_PATH.CUSTOMER
  },
  {
    id: "client.product",
    to: PARTNER_PATH.PRODUCT
  },
  {
    id: "client.operation",
    to: PARTNER_PATH.OPERATION
  }
];

export const SUPPORT_APP_SHARE_PATH = [
  INTELLIGENCE_PATHS.INTELLIGENCE_NEWS_DETAIL,
  INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH_DETAIL,
  INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_DETAIL,
  INTELLIGENCE_PATHS.INTELLIGENCE_MARKET_DAILY_DETAIL,
  AGREEMENT_PATH,
  POLICY_PATH,
  DOWNLOAD_PATH,
  PROFILE_PATHS.PROFILE_KYC_APPUPLOAD,
  YIELD_PATHS.YIELD_APP_BTC_YTD,
  ASSETS_PATHS.ASSETS_MOBILE,
  EVENTS_PATHS.EVENTS_DETAIL,
  TERMINATION_PATH,
];

export const BEFORE_LOGIN_SENAD_EMAIL_PATH = [
  LOGIN_PATHS.LOGIN,
  LOGIN_PATHS.LOGIN_REGISTER,
  LOGIN_PATHS.LOGIN_REGISTER_MULTI,
  LOGIN_PATHS.LOGIN_RESET
];
