import type { IRes ,
  IReqAumProportion,
  IReqCustomerAumProportion,
  IReqListProductBusiness,
  IReqOperationAum,
  IReqOperationBase,
  IReqOperationClientAssetSummary,
  IReqOperationClientCustomerAssets,
  IReqOperationClientCustomerAssetsAllocation,
  IReqOperationClientCustomerSessionHistory,
  IReqOperationClientCustomerSessionStatistics,
  IReqOperationClientCustomerWalletList,
  IReqOperationClientRecent,
  IReqOperationClientSessionStatistics,
  IReqProductList,
  IReqRecentVisit,
  IReqStakingAum,
  IReqStakingMonthlyParams,
  IReqStructuredAnalysisParams,
  IReqStructuredDataParams,
  IReqStructuredOrdersParams,
  IReqStructuredProductsParams,
  IReqSubscriptions,
  IReqTradeAnalysisParams,
  IReqTradeOrdersParams,
  IReqTradeVolumeParams,
  IReqVentureInterested,
  IResStructuredOrder,
  IResVentureAumProportion,
  IResVentureProduct
} from "@aspen/model/index";
import axiosInstance from "../http";
import { operationApi as api } from "./api";
import { operationServer } from "@aspen/libs/index";

// @ts-ignore
const getDefaultConfig = (token) => ({
  baseURL: operationServer,
  headers: {
    Authorization: token
  }
});

/**
 * @description: operation获取代理商的客户数据
 * @params {}
 * @return {Promise}
 */
export const getOperationClient = (token: string, params: IReqOperationBase): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.client, {
      params,
      ...getDefaultConfig(token)
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商的客户月度AUM
 * @params {IReqAum}
 * @return {Promise}
 */
export const getOperationAum = (token: string, params: IReqOperationAum): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.aum, {
      params,
      ...getDefaultConfig(token)
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商的客户最近活动数据
 * @params {IReqClientRecent}
 * @return {Promise}
 */
export const getOperationRecent = (
  token: string,
  params: IReqOperationClientRecent
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.recent, {
      params,
      ...getDefaultConfig(token)
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商的客户资产汇总信息
 * @params {IReqClientAssetSummary}
 * @return {Promise}
 */
export const getOperationAssetSummary = (
  token: string,
  params: IReqOperationClientAssetSummary
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.assetSummary, {
      params,
      ...getDefaultConfig(token)
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商的客户资产汇总信息详情
 * @params { customerId: string }
 * @return {Promise}
 */
export const getOperationAssetSummaryDetail = (
  token: string,
  params: { customerId: string }
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.assetSummaryDetail, {
      params,
      ...getDefaultConfig(token)
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商的客户事件统计信息
 * @params {IReqClientSessionStatistics}
 * @return {Promise}
 */
export const getOperationSessionStatistics = (
  token: string,
  params: IReqOperationClientSessionStatistics
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.sessionStatistics, {
      params,
      ...getDefaultConfig(token)
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商的客户事件统计信息
 * @return {Promise}
 */
export const getOperationMonitorTypes = (token: string): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.monitorTypes, {
      ...getDefaultConfig(token)
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商的客户资产汇总信息
 * @params {IReqClientCustomerAssets}
 * @return {Promise}
 */
export const getOperationCustomerWallet = (
  token: string,
  params: IReqOperationClientCustomerAssets
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.walletAssets, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商的客户资产分布
 * @params {IReqClientCustomerAssetsAllocation}
 * @return {Promise}
 */
export const getOperationCustomerAssetAllocation = (
  token: string,
  params: IReqOperationClientCustomerAssetsAllocation
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.assetAllocation, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerSpot = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.spotAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerFlexible = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.flexibleAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerFixed = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.fixedAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerStaking = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.stakingAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerDual = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.dualAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerAccumulator = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.accumulatorTableAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerSnowball = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.snowballTableAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerStrategy = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.strategyAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerFund = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.fundAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取代理商客户钱包表格信息
 * @params {IReqOperationClientCustomerWalletList}
 * @return {Promise}
 */
export const getOperationCustomerVenture = (
  token: string,
  params: IReqOperationClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.ventureAccount, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取recent sessions
 * @params {IReqOperationClientCustomerSessionDetails}
 * @return {Promise}
 */
export const getOperationCustomerSessionHistory = (
  token: string,
  params: IReqOperationClientCustomerSessionHistory
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.sessionHistory, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取recent sessions statistics
 * @params {IReqOperationClientCustomerSessionDetails}
 * @return {Promise}
 */
export const getOperationCustomerSessionStatistics = (
  token: string,
  params: IReqOperationClientCustomerSessionStatistics
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.sessionDetailStatistics, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: operation获取销售列表
 * @params {}
 * @return {Promise}
 */
export const getOperationEmployees = (token: string): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.employees, { ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: operation venture product list
 * @params {IReqProductList}
 * @return {Promise}
 */
export const getOperationVentureList = (
  token: string,
  params: IReqProductList
): Promise<IResVentureProduct> => {
  return axiosInstance
    .get(api.operationCms.ventureList, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: operation venture product recent visit
 * @params {IReqRecentVisit}
 * @return {Promise}
 */
export const getOperationVentureRecentVisit = (
  token: string,
  params: IReqRecentVisit
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.ventureRecentVisit, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: operation venture product interested
 * @params {IReqVentureInterested}
 * @return {Promise}
 */
export const getOperationVentureInterested = (
  token: string,
  params: IReqVentureInterested
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.ventureInterested, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: operation venture product subscriptions
 * @params {IReqSubscriptions}
 * @return {Promise}
 */
export const getOperationVentureSubscriptions = (
  token: string,
  params: IReqSubscriptions
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.ventureSubscriptions, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询产品的申购记录用户
 * @params {IReqSubscriptions}
 * @return {Promise}
 */
export const getOperationStakingSubscriptions = (
  token: string,
  params: IReqSubscriptions
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.stakingSubscriptions, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取产品持仓AUM及增幅占比等信息
 * @params {IReqAumProportion}
 * @return {Promise}
 */
export const getOperationVentureAumProportion = (
  token: string,
  params: IReqAumProportion
): Promise<IResVentureAumProportion> => {
  return axiosInstance
    .get(api.operationCms.ventureAumProportion, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: operation venture product customer aum proportion
 * @params {IReqCustomerAumProportion}
 * @return {Promise}
 */
export const getOperationVentureCustomerAumProportion = (
  token: string,
  params: IReqCustomerAumProportion
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.ventureCustomerAumProportion, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询产品的业务监控
 * @params {IReqListProductBusiness}
 * @return {Promise}
 */
export const getOperationListProductBusiness = (
  token: string,
  params: IReqListProductBusiness
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.listProductBusiness, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询代理商下属客户Staking产品按月统计的Aum
 * @params {IReqStakingAum}
 * @return {Promise}
 */
export const getOperationStakingAum = (token: string, params: IReqStakingAum): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.stakingAum, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询代理商下属客户操作Staking产品的事件数（按月统计）
 * @params {IReqStakingMonthlyParams}
 * @return {Promise}
 */
export const getOperationStakingEvent = (
  token: string,
  params: IReqStakingMonthlyParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.stakingEvent, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询代理商下属客户Staking产品按月统计的持仓人数
 * @params {IReqStakingMonthlyParams}
 * @return {Promise}
 */
export const getOperationStakingSubscriber = (
  token: string,
  params: IReqStakingMonthlyParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.stakingSubscriber, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 分页查询用户交易订单记录
 * @params {IReqTradeOrdersParams}
 * @return {Promise}
 */
export const getOperationTradeOrders = (
  token: string,
  params: IReqTradeOrdersParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.tradeOrders, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 客户交易分析对比历史增量增幅等信息
 * @params {IReqTradeAnalysisParams}
 * @return {Promise}
 */
export const getOperationTradeAnalysis = (
  token: string,
  params: IReqTradeAnalysisParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.tradeAnalysis, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 客户交易分析对比历史增量增幅等信息
 * @params {IReqTradeVolumeParams}
 * @return {Promise}
 */
export const getOperationTradeVolume = (
  token: string,
  params: IReqTradeVolumeParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.tradeVolume, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询代理商下属客户按月统计的订单交易人数
 * @params {IReqTradeVolumeParams}
 * @return {Promise}
 */
export const getOperationTradeTrader = (
  token: string,
  params: IReqTradeVolumeParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.tradeTrader, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询代理商下属客户按月统计的订单交易事件数
 * @params {IReqTradeVolumeParams}
 * @return {Promise}
 */
export const getOperationTradeEvent = (
  token: string,
  params: IReqTradeVolumeParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.tradeEvent, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询代理商下属客户按月统计的月末现货aum
 * @params {IReqTradeVolumeParams}
 * @return {Promise}
 */
export const getOperationTradeAum = (
  token: string,
  params: IReqTradeVolumeParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.tradeAum, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 分页获取结构化产品订单
 * @params {IReqStructuredOrdersParams}
 * @return {Promise}
 */
export const getOperationStructuredOrders = (
  token: string,
  params: IReqStructuredOrdersParams
): Promise<IResStructuredOrder> => {
  return axiosInstance
    .get(api.operationCms.structuredOrders, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取结构化产品交易量占比增幅等信息
 * @params {IReqStructuredAnalysisParams}
 * @return {Promise}
 */
export const getOperationStructuredAnalysis = (
  token: string,
  params: IReqStructuredAnalysisParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.structuredAnalysis, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取结构化产品列表
 * @params {IReqStructuredProductsParams}
 * @return {Promise}
 */
export const getOperationStructuredProducts = (
  token: string,
  params: IReqStructuredProductsParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.structuredProducts, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 按月统计的结构化理财产品持有人数
 * @params {IReqStructuredDataParams}
 * @return {Promise}
 */
export const getOperationStructuredSubscriber = (
  token: string,
  params: IReqStructuredDataParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.structuredSubscriber, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 按月统计的结构化理财产品事件数
 * @params {IReqStructuredDataParams}
 * @return {Promise}
 */
export const getOperationStructuredEvent = (
  token: string,
  params: IReqStructuredDataParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.structuredEvent, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 按月统计的结构化理财产品aum
 * @params {IReqStructuredDataParams}
 * @return {Promise}
 */
export const getOperationStructuredAum = (
  token: string,
  params: IReqStructuredDataParams
): Promise<IRes> => {
  return axiosInstance
    .get(api.operationCms.structuredAum, { params, ...getDefaultConfig(token) })
    .then((res) => {
      return res?.data;
    });
};
